// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bransjer-anlegg-js": () => import("./../../../src/pages/bransjer/anlegg.js" /* webpackChunkName: "component---src-pages-bransjer-anlegg-js" */),
  "component---src-pages-bransjer-energi-js": () => import("./../../../src/pages/bransjer/energi.js" /* webpackChunkName: "component---src-pages-bransjer-energi-js" */),
  "component---src-pages-bransjer-index-js": () => import("./../../../src/pages/bransjer/index.js" /* webpackChunkName: "component---src-pages-bransjer-index-js" */),
  "component---src-pages-bransjer-landbruk-js": () => import("./../../../src/pages/bransjer/landbruk.js" /* webpackChunkName: "component---src-pages-bransjer-landbruk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-oss-js": () => import("./../../../src/pages/kontakt-oss.js" /* webpackChunkName: "component---src-pages-kontakt-oss-js" */),
  "component---src-pages-kontakt-oss-takk-js": () => import("./../../../src/pages/kontakt-oss/takk.js" /* webpackChunkName: "component---src-pages-kontakt-oss-takk-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-tjenester-grunnundersokelser-js": () => import("./../../../src/pages/tjenester/grunnundersokelser.js" /* webpackChunkName: "component---src-pages-tjenester-grunnundersokelser-js" */),
  "component---src-pages-tjenester-inspeksjon-js": () => import("./../../../src/pages/tjenester/inspeksjon.js" /* webpackChunkName: "component---src-pages-tjenester-inspeksjon-js" */),
  "component---src-pages-tjenester-kartlegging-js": () => import("./../../../src/pages/tjenester/kartlegging.js" /* webpackChunkName: "component---src-pages-tjenester-kartlegging-js" */),
  "component---src-pages-tjenester-opplaering-js": () => import("./../../../src/pages/tjenester/opplaering.js" /* webpackChunkName: "component---src-pages-tjenester-opplaering-js" */),
  "component---src-pages-tjenester-radgiving-js": () => import("./../../../src/pages/tjenester/radgiving.js" /* webpackChunkName: "component---src-pages-tjenester-radgiving-js" */),
  "component---src-pages-tjenester-romvesen-cloud-js": () => import("./../../../src/pages/tjenester/romvesen-cloud.js" /* webpackChunkName: "component---src-pages-tjenester-romvesen-cloud-js" */)
}

